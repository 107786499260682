export const TRANSLATION = {
	'en-EN':{
		'INSERITO': 'inserted',
		'LETTERA VETTURA': 'waybill',
		'LAVORAZIONE': 'processing',
        'PARZIALMENTE EVASO': 'part. dispatched',
        'EVASO': 'dispatched',
        'RESO': 'returned',
        'RESO PARZIALE': 'part. returned',


		'waybill': 'lettera vettura',
		'processing': 'lavorazione',
		'part. dispatched' : 'parzialmente evaso',
		'dispatched': 'evaso',
		'returned' : 'reso',
		'part. returned': 'reso parz.',
	},
	'it-IT': {
		//error messages
		'Error': 'Errore',
		'Error in fetching data': 'Errore nel recupero dei dati',
		'Authentication failed': 'Autenticazione fallita',
		'Camera not found': 'Fotocamera non trovata',

		//New version popup
		'New version': 'Nuova versione',
		'Welcome to the new version of eversell app!': "Benvenuti nella nuova versione dell'app Eversell!",

		//login
		'Log In': 'Accedi',
		'Welcome on eversell': 'Benvenuti su eversell',
		'Company': 'Azienda',
		'Username': 'Utente',
		'Password': 'Password',
		'Remember me': 'Ricorda credenziali', //dubbio Stay connected-Resta connesso oppure Remember me-Ricorda credenziali?
		'Forgot password?': 'Password dimenticata?',
		'Sign In': 'Accedi',
		'Required field': 'Campo obbligatorio', //dubbio richiesto
		'Required fields': 'Campi obbligatori', //dubbio richiesto

		//header 
		'admin': 'admin', //dubbio
		'store': 'negozio', //dubbio
		'customer': 'cliente',

		//sidebar
		'Product Lookup': 'Ricerca prodotti', //dubbio
		'Sales Dashboard': 'Vendite', //dubbio pannello? Bacheca vendite?
		'Customer': 'Cliente',
		'Settings': 'Impostazioni',
		'Logout': 'Esci', //dubbio  lasciare logout?
		'Version': 'Versione',

		//load panel
		'Loading...': 'Caricamento...',

		//footer filter
		'<ALL>': '<TUTTI>',
		'season': 'stagione',
		'department': 'reparto',
		'supplier': 'fornitore',

		//Product lookup
		// 'Product lookup': 'Ricerca prodotti',
		'Products not found.': 'Nessun articolo trovato.',
		'Search': 'Cerca', //also in popup filter
		'Search item': 'Cerca articolo',
		'Item': 'Articolo', //dubbio 'Prodotto'
		'Barcode': 'Barcode', //dubbio 'Codice a barre'
		'Available': 'Disponibile',
		'Reserved': 'Riservato',
		'In Order': 'In ordine',
		'Price': 'Prezzo',
		'Color': 'Colore',
		'Available only': 'Solo disponibili',

		//QR code reader
		'Barcode reader': 'Lettore barcode',
		'Retry': 'Riprova',
		'back': 'indietro',

		//Product detail
			//footer
		'description': 'descrizione',
		'stock': 'stock',
			//description
		'Code': 'Codice',
		'Model': 'Modello',
		'Description': 'Descrizione',
		'Sell Price': 'Prezzo di vendita',
		'Brand': 'Brand', //dubbio
		'Supplier': 'Fornitore',
		'Season': 'Stagione',
		'Department': 'Reparto',
		'Category': 'Categoria',
		'Subcategory': 'Sottocategoria',
		'Product Type': 'Tipo prodotto',
		'Line': 'Linea',
		'Composition': 'Composizione',
			//stock
		'Store': 'Negozio',
		'Avail': 'DSP',
		'Sold': 'VEN',
		'Qty': 'Qtà', //dubbio
		'qty' : 'qtà',
		'Size': 'Taglia',
		'Available others': 'Altri disponibili',
		
			//KPI
		// 'Sell Through': 'Vendite', //dubbio
		// 'Week Sell Through': 'Vendite Settimanali', //dubbio
		// 'Gross Margin': 'Margine lordo', //dubbio
		// 'Week Presence': 'Presenza settimanale', //dubbio
		'General information': 'Informazioni generali', 
		'Value': 'Valore',
		'Buy Price': "Prezzo d'acquisto",
		'Buy': 'Acquistati',
		'Sell': 'Venduti',

		//sales dashboard
		// 'store': 'negozio',
		'options': 'opzioni',
		'Options': 'Opzioni',
		'Gross': 'Lordo',
		'Cumulative': 'Cumulativo',
		'day': 'giorno',
		'month': 'mese',
		'year': 'anno',
		'Today': 'Oggi',
		'Yesterday': 'Ieri',
		'Current month': 'Mese corrente',
		'Last month to date': 'Mese precedente ad oggi', //dubbio
		'Last month': 'Mese precedente',
		'diff Cm/Lmtd': 'diff Cm/Lmtd', //dubbio
		'Current year': 'Anno corrente',
		'Last year to date': 'Anno precedente ad oggi', //dubbio
		'Last year': 'Anno precedente',
		'diff Cy/Lytd': 'diff Cy/Lytd', //dubbio

		'all': 'tutti',
		'instore': 'in negozio',
		'online': 'online',

		'Current': 'Corrente',
		'Last to date': 'Precedente ad oggi',
		'LTD': 'Prec. ad oggi',
		'Last': 'Precedente',
		'diff C/Ltd': 'diff C/Ltd', //dubbio
		'No data': 'Nessun dato',
		'to date': 'ad oggi',
		'to this time': "a quest'ora",
		'whole': 'intero',

		'Day sales': 'Vendite giornaliere',
		'Month sales': 'Vendite mensili',
		'Year sales': 'Vendite annuali',

		'Sales in store': 'Vendite in negozio',
		'Sales by channel': 'Vendite per canale',

		'Click on the legend to show and hide the series': 'Clicca sulla legenda per mostrare e nascondere le serie',
		'Compare with last month': 'Confronta con mese precedente',
		'Compare with same month of last year': "Confronta con stesso mese dell'anno prec.",


		//orders
		'Orders': 'Ordini',
		'Order': 'Ordine',
		'inserted': 'inserito',
		'waybill': 'lettera vettura',
		'processing': 'lavorazione',
		'part. dispatched' : 'parzialmente evaso',
		'dispatched': 'evaso',
		'returned' : 'reso',
		'part. returned': 'reso parz.',
		'channel': 'canale',
		'status': 'stato',
		// 'archive': 'archivio',
		'history': 'storico',
		'open': 'aperti',
		'closed': 'chiusi',
		// 'all': 'tutti',
		'item': 'articolo',
		'Items': 'Articoli',
		'Order Detail': 'Dettaglio ordine',
		'Order not found.': 'Ordine non trovato.',
		'Shipping address': 'Indirizzo di spedizione',
		'Edit': 'Modifica',
		'Customer Orders Detail': 'Dettaglio ordini cliente',
		'There are' : 'Ci sono',
		'new orders' : 'nuovi ordini',
		'view': 'vedi',
		'this month' : 'mese corrente',
		'last month' : 'mese precedente',		
		'last 3 months' : 'ultimi 3 mesi',	
		'select dates': 'seleziona date',	
		// 'this year' : "anno corrente",
		// 'last 3 years' : 'ultimi 3 anni',
		'Orders not found.' : 'Nessun ordine trovato.',
		'Week': 'Sett.',
		'Overview orders': 'Panoramica ordini', //Riepilogo?


		//customer
		'Personal Information': 'Informazioni personali',
		'Personal Data': 'Dati personali',
		'Name':'Nome',
		'Surname':'Cognome',
		'Birth Date': 'Data di nascita',
		'Gender':'Genere',
		'Select...': 'Seleziona...',
		'Male': 'Maschio',
		'Female': 'Femmina',
		'Other': 'Altro',
		'Prefer not to tell': 'Preferisco non dirlo',

		'Contacts': 'Contatti',
		'Mobile':'Cellulare',
		'Address':'Indirizzo',
		'Province':'Provincia',
		'City':'Città',

		'I consent to the processing of my data *': 'Acconsento al trattamento dei miei dati *',
		'I want to receive e-mails': 'Desidero ricevere e-mail',

		'submit': 'invia',

		// 'I confirm treatment of personal data and its use':'Confermo il trattamento dei dati e  il suo utilizzo',
		// 'Confirm':'Conferma',
		// 'Error':'Errore',

		'Do not use digits in this field': 'Non usare cifre in questo campo',
		'Email is not valid': 'Email non valida',
		'Phone number must have country prefix': 'Numero di telefono deve avere il prefisso (es. +39)',

		'Customer saved successfully':'Cliente salvato con successo',
		'Error in saving customer': 'Errore nel salvataggio del cliente',


		//Inventory
		'Inventory' : 'Inventario',
		'Inventories management': 'Gestione inventari',
		'Delete confirmation': 'Conferma eliminazione',
		'Edit confirmation': 'Conferma modifica',
		'Exit confirmation': 'Conferma uscita',
		'Cancel': 'Annulla',
		'cancel': 'annulla',
		'Delete' : 'Elimina',
		'delete' : 'elimina',
		'Are you sure you want to delete this item from the current inventory?' : 'Sei sicuro di voler eliminare questo articolo dall’inventario corrente?',
		'Are you sure you want to delete this item?' : 'Sei sicuro di voler eliminare questo articolo?',
		'Are you sure you want to edit this item?': 'Sei sicuro di voler modificare questo articolo?',
		'Do you want to save the changes before exiting? All the unsaved changes will be lost.' : 'Vuoi salvare le modifiche prima di uscire? Tutti le modifiche non salvate verranno perse.',
		'Detected item' : 'Articolo rilevato',
		'save': 'salva',
		'exit': 'esci',
		'Item added successfully': 'Articolo aggiunto con successo',
		'Error in adding item': 'Errore nell\'aggiunta dell\'articolo',
		'new': 'nuovo',
		'ongoing': 'in corso',
		// 'archive': 'archivio',
		'Date': 'Data',
		'Inventory created successfully': 'Inventario creato con successo',
		'Error in creating inventory': "Errore nella creazione dell'inventario",
		'Type': 'Tipo',
		'Total': 'Totale',
		'Partial': 'Parziale',
		'On detected items only': 'Solo su articoli rilevati',
		'Attribute': 'Attributo',
		'New inventory': 'Nuovo inventario',
		'Partial inventory': 'Inventario parziale',
		'create': 'crea',
		'type': 'tipo',
		'Confirmed': 'Confermato',
		'Detected': 'Rilevato',
		'size': 'taglia',
		'detected qty': 'qtà rilevata',
		'stock qty': 'qtà stock',
		'difference': 'differenza',
		'complete detection': 'concludi rilevazione',
		// 'complete': 'concludi',
		'reopen detection': 'riapri rilevazione', //resume?
		// 'reopen': 'riapri', //resume?
		'confirm inventory': 'conferma inventario',
		'confirm': 'conferma',
		// 'back': 'indietro',
		'print': 'stampa',

		
		// 'Confirmed': 'Confermato',

		//Barcode association
		'Barcode association': 'Associazione barcode',
		'associate': 'associa',
		'Use digits only in this field': 'Usa solo numeri in questo campo',
		'Internal barcode': 'Barcode interno',
		'Manufacturer barcode': 'Barcode produttore',
		'Barcode associated successfully': "Barcode associato con successo",
		'Error in associating barcode': "Errore nell'associazione del barcode",
		'Product not found.': 'Articolo non trovato.',
		'No product selected.' : 'Nessun articolo selezionato.',

		//Reprint labels
		'Reprint labels': 'Ristampa etichette',
		'Select a store': 'Seleziona un negozio',
		'No store found': 'Nessun negozio trovato',
		'go': 'vai',
		'Items list': 'Lista articoli',
		'continue': 'continua',
		'Print options': 'Opzioni di stampa',
		'Label': 'Etichetta',
		'Choose the label format *': 'Scegli il formato etichetta *',
		'Print labels *': 'Stampa etichette *', 
		'from detection': 'da rilevazione',
		'from stock': 'da giacenza',
		'qty to add': 'qtà da aggiungere',
		'Start from label *': 'Inizia da etichetta *',
		'* mandatory fields': '* campi obbligatori',
		'Select a valid label format for your store': 'Seleziona un formato di etichetta valido per il tuo negozio',
		'PDF downloaded in the Downloads folder': 'PDF scaricato nella cartella Download',
		// 'Cancel': 'Annulla',
		'Add': 'Aggiungi',
		'Resume previous list?': 'Recupera lista precedente?',
		'A previous list is available in the memory, do you want to continue it?': 'Una lista precedente è disponibile in memoria, si desidera continuarla?',
		'new list': 'nuova lista',
		'continue list':'continua lista', 
		'Store not found, select a valid store': 'Negozio non trovato, selezionare un negozio valido',
		'sz': 'tg',
		'unexistent barcode' : 'barcode inesistente',
		'Current mode: ': 'Modalità attuale: ',
		'sequence': 'sequenza',
		// 'grouped':'raggruppa',
		'Do you want to print labels in the same sequence as you add the items (SEQUEN. ' : 'Vuoi stampare le etichette nella stessa sequenza in cui aggiungi gli articoli (SEQUEN. ',
		') or grouping them by item code and size (GROUPED ' : ') o raggruppandole per codice e taglia dell\'articolo (RAGGRUP. ',
		'Warning: changing mode will delete the current list': 'Attenzione: cambiare modalità cancellerà la lista corrente',
		'grouped': 'raggrup.',
		'New list?': 'Nuova lista?',
		'Do you want to start a new list?': 'Vuoi iniziare una nuova lista?',
		'Warning: starting a new list will delete the current list': 'Attenzione: iniziare una nuova lista cancellerà la lista corrente',





		//FAQs
			"What can I do?": "Cosa posso fare?",
			"You can search for an item either by barcode or by name. You can print the labels based on stock quantities or based on your detected quantity (how many labels you've manually entered). See the references below for more information.": "Puoi cercare un articolo sia tramite barcode che tramite nome. Puoi stampare le etichette in base alla giacenza o in base alla rilevazione (quante etichette hai inserito manualmente). Vedi i riferimenti qui sotto per maggiori informazioni.",

			"Select a store / change selected store (admin)": "Selezionare un negozio / modificare il negozio selezionato (admin)",
			'If you have an admin account, you can choose the store to associate with label printing. To change it, click the store icon at the top right, next to the burger menu, and select the new store in the popup that appears. You can scan the store barcode, if available for your company, by clicking on the camera icon. You can also change the store by clicking the "edit" icon (sheet and pencil) on the print confirmation page.': 'Se hai un account admin, puoi scegliere il negozio da associare alla stampa etichette. Per cambiarlo, clicca l\'icona del negozio in alto a destra, vicino al burger menu e seleziona il nuovo negozio nel popup che appare. Puoi scansionare il barcode del negozio, se disponibile per la tua azienda, cliccando l\'icona della fotocamera. Puoi cambiare negozio anche cliccando sull\'icona "modifica" (foglio e matita) nella pagina di conferma della stampa.',

			"Select the printing mode": "Selezionare la modalità di stampa",
			'You can print labels in the same sequence as you add the items ("SEQUEN.") or grouping them by item code and size ("GROUPED"). You can change the printing mode by clicking on the icon in the bottom-left. Warning: this will delete the current list and start a new one.' : 'Puoi stampare le etichette nella stessa sequenza in cui aggiungi gli articoli ("SEQUEN.") o raggruppandole per codice e taglia dell\'articolo ("RAGGRUP."). Puoi cambiare la modalità di stampa cliccando l\'icona in basso a sinistra. Attenzione: cambiare la modalità di stampa cancellerà la lista corrente e ne inizierà una nuova.',

			"Start a new list":"Iniziare una nuova lista",
			'If you have a current items list and want to start a new one, click on the icon in the bottom-right (+). Warning: starting a new list will delete the current one.':'Se hai una lista corrente di articoli e vuoi iniziarne una nuova, clicca l\'icona in basso a sinistra (+). Attenzione: iniziare una nuova lista cancellerà quella corrente.',








						
			"Item search by barcode (manual)": "Ricerca articolo tramite barcode (manuale)",
			'In the top section, "barcode" is selected by default. Enter the full barcode in the designated field and click the "GO" button / enter key. The corresponding item (with size) will be added to the list.': 'Nella sezione in alto di default è selezionato "barcode". Digita il barcode completo nell’apposito campo e clicca "VAI" / invio. Il corrispondente articolo (con taglia) verrà aggiunto alla lista.',
			
			"Item search by barcode (scanning)": "Ricerca articolo tramite barcode (scansione)",
			'In the top section, "barcode" is selected by default. You can also search for the barcode using the camera. Click the camera icon next to the "GO" button, and a barcode scanner will open. Scan the barcode and choose whether to add the scanned item to the list ("ADD" button) or discard it ("CANCEL" button).': 'Nella sezione in alto di default è selezionato "barcode". Puoi cercare il barcode anche usando la fotocamera. Clicca sull’icona della fotocamera accanto al pulsante "VAI", si aprirà un lettore di barcode. Inquadra il barcode e scegli se aggiungere l’articolo scannerizzato alla lista (tasto "AGGIUNGI") o se scartarlo (tasto "ANNULLA").',
			
			"Item search by name": "Ricerca articolo tramite nome",
			'In the top section, select "item" instead of "barcode". Enter keywords for the item you want to search for and click "GO". The item or a list of items will appear below. Click "OK" to add the corresponding item. A popup will appear where you can enter the quantity per size.': 'Nella sezione in alto, seleziona "articolo" anziché "barcode". Digita parole chiave dell’articolo che desideri cercare e clicca "VAI". Nella scheda sottostante apparirà l’articolo o una lista di articoli. Clicca su "OK" per aggiungere il corrispondente articolo. Apparirà un popup nel quale potrai inserire la quantità per taglia.',
			
			"Add / modify item quantities": "Aggiungere / modificare le quantità di un articolo",
			"Subsequent additions of quantities will be added to the current quantities, not replace them. You can modify the quantities in the current list of selected items in the designed field (field on the right).": "Aggiunte successive di quantità si vanno a sommare, non a sostituire, alle attuali quantità inserite. Puoi modificare le quantità nella attuale lista di articoli selezionati nel campo apposito (riquadro a destra).",
			
			"Delete an item": "Eliminare un articolo",
			"To delete an item, swipe from right to left on the desired item. Confirm or cancel the deletion in the popup that will appear.": "Per eliminare un articolo, scorri da destra a sinistra sull’articolo desiderato. Conferma o annulla l’eliminazione nel popup che apparirà.",
			
			"Print stock quantities": "Stampare la giacenza",
			'To print labels based on the stock quantity of an item, simply add the item once to the list and select the "print labels [from stock]" option in the confirmation page.': 'Per stampare le etichette in base alla giacenza di un articolo, è sufficiente inserirlo una sola volta nella lista e selezionare l’opzione "stampa etichette [da giacenza]" nella pagina di conferma.',
			
			"Print a specific number of labels per size": "Stampare un numero specifico di etichette per taglia",
			'Enter the item and modify the quantities as described above. You can also modify the quantities on the print confirmation page. On this confirmation page, select the "print labels [from detection]" option.': 'Inserisci l’articolo e modifica le quantità come sopra descritto. Potrai modificare le quantità anche nella pagina di conferma della stampa. In questa pagina di conferma, selezionare l’opzione "stampa etichette [da rilevazione]"',

			"I closed the app / reloaded the page, do I have to start the list from scratch?": "Ho chiuso l’app / ricaricato la pagina, devo ricominciare la lista da capo?",
			"No. The label list is automatically saved every 2 seconds. After reloading the page, you will be asked if you want to continue the previous list or start a new one. Starting a new one will automatically delete the previously saved list.": "No. La lista di etichette si salva automaticamente ogni 2 secondi. Dopo il ricaricamento della pagina, ti verrà chiesto se vuoi continuare la lista precedente o se vuoi iniziare una nuova lista. Iniziare una nuova lista cancellerà automaticamente la lista precedentemente salvata",
			
			"Label format": "Formato etichette",
			"Choose the desired label format, which you know is available for your store. If the selected label format is not available for your store, an error message will appear. Simply select an available format and relaunch the print.": "Scegli il formato di etichette desiderato, che sai essere disponibile per il tuo negozio. Nel caso in cui il formato etichetta selezionato non sia disponibile per il tuo negozio, apparirà un messaggio di errore. Sarà sufficiente selezionare un formato idoneo e rilanciare la stampa.",
			
			"Start from label": "Inizia da etichetta",
			'Sets the position where printing should start. For example, printing from label 2 will skip the first label position (leaving a white label) and will start printing the first label of the list from label position n 2. This option is useful e.g. if you have an already partially used A4 labels sheet.': 'Imposta la posizione da cui deve iniziare la stampa. Per esempio, stampando dall\'etichetta 2 si salterà la prima posizione (lasciando un\'etichetta bianca) e si inizierà a stampare la prima etichetta dell\'elenco dalla seconda posizione. Questa opzione è utile, per esempio, se hai un foglio etichette A4 già parzialmente usato.',
	

		


		//settings
		'Account': 'Profilo',
		'Role': 'Ruolo',
		'Language': 'Lingua',
		'Preferences': 'Preferenze',


		//PWA
		'A new version of the app is available. Would you like to reload?' : "Una nuova versione dell'app è diponibile. Desideri aggiornarla?"
	}
};

//todo
//files that uses this TRADUCTION in the original app: app/filters.js
// app.filter('translate', function(TRADUCTION, $cookies) {
// 	return function(input) {
// 		try {
// 			var output = TRADUCTION[$cookies.get('language')][input];
// 			return ((typeof output)=='undefined') ? input : output;
// 		} catch(err) {
// 			return input;
// 		}
// 	}
// });